import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Tabs, Spin, Menu, Dropdown, Modal, Button } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import {
  fetchActiveUsers,
  fetchBlackListedUsers,
} from "../../store/slices/adminUsersListSlice";
import {
  assignUserRole,
  toggleBlacklistUser,
} from "../../store/slices/userListActionSlice";

const { TabPane } = Tabs;
const { confirm } = Modal;

const UserTable = () => {
  const dispatch = useDispatch();
  const [tabKey, setTabKey] = useState("active");

  const activeUsers = useSelector((state) => state?.usersList?.active);
  const blackListedUsers = useSelector(
    (state) => state?.usersList?.blackListed
  );

  const [activeData, setActiveData] = useState([]);
  const [blackListedData, setBlackListedData] = useState([]);

  useEffect(() => {
    if (tabKey === "active") {
      dispatch(fetchActiveUsers());
    } else if (tabKey === "blackListed") {
      dispatch(fetchBlackListedUsers());
    }
  }, [tabKey]);

  useEffect(() => {
    if (activeUsers?.data) {
      setActiveData(activeUsers.data.data || []);
    }
  }, [activeUsers]);

  useEffect(() => {
    if (blackListedUsers?.data) {
      setBlackListedData(blackListedUsers.data.data || []);
    }
  }, [blackListedUsers]);

  const handleConfirm = (action, record, role = null) => {
    confirm({
      title: `Are you sure you want to ${action}?`,
      onOk() {
        if (action === "Blacklist User") {
          handleBlacklistUser(record.id);
        } else if (action === "Unblacklist User") {
          handleUnblacklistUser(record.id);
        } else if (action === "Set User Role") {
          handleChangeRole(record.email, role);
        }
      },
      onCancel() {},
    });
  };

  const menu = (record) => (
    <Menu>
      {record.userType === "USER" && (
        <Menu.Item
          onClick={() => handleConfirm("Set User Role", record, "ADMIN")}
        >
          Make Admin
        </Menu.Item>
      )}
      {record.userType === "ADMIN" && (
        <Menu.Item
          onClick={() => handleConfirm("Set User Role", record, "USER")}
        >
          Make User
        </Menu.Item>
      )}
      {(record.userType === "USER" || record.userType === "ADMIN") &&
        (record.blackListed ? (
          <Menu.Item onClick={() => handleConfirm("Unblacklist User", record)}>
            Unblacklist User
          </Menu.Item>
        ) : (
          <Menu.Item onClick={() => handleConfirm("Blacklist User", record)}>
            Blacklist User
          </Menu.Item>
        ))}
    </Menu>
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Affiliation",
      dataIndex: "affiliation",
      key: "affiliation",
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: "Profile Complete",
      dataIndex: "profileComplete",
      key: "profileComplete",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: "Country Code",
      dataIndex: "countryCode",
      key: "countryCode",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) =>
        record?.userType === "SUPER_ADMIN" ? null : (
          <Dropdown overlay={menu(record)} trigger={["click"]}>
            <Button type="text" icon={<EllipsisOutlined />} />
          </Dropdown>
        ),
    },
  ];

  const handleChangeRole = (email, type) => {
    dispatch(assignUserRole({ email, type })).then((res) => {
      if (tabKey === "active") {
        dispatch(fetchActiveUsers());
      }
    });
  };

  const handleBlacklistUser = (userId) => {
    dispatch(toggleBlacklistUser({ userId, blocked: true })).then((res) => {
      dispatch(fetchActiveUsers());
    });
  };

  const handleUnblacklistUser = (userId) => {
    dispatch(toggleBlacklistUser({ userId, blocked: false })).then((res) => {
      dispatch(fetchBlackListedUsers());
    });
  };

  const renderTable = () => {
    if (tabKey === "active") {
      console.log("blackListedData", activeData);
      return (
        <Table
          columns={columns}
          dataSource={activeData}
          rowKey="id"
          pagination={false}
        />
      );
    } else if (tabKey === "blackListed") {
      console.log("blackListedData", blackListedData);
      return (
        <Table
          columns={columns}
          dataSource={blackListedData}
          rowKey="id"
          pagination={false}
        />
      );
    }
    return null;
  };

  if (
    activeUsers?.status === "loading" ||
    blackListedUsers?.status === "loading"
  ) {
    return (
      <div className="fullPageLoading">
        <Spin tip="Loading..." />
      </div>
    );
  }

  return (
    <div className="pageContainer">
      <h3>User Information</h3>
      <Tabs activeKey={tabKey} onChange={(key) => setTabKey(key)}>
        <TabPane tab="Active" key="active" />
        <TabPane tab="BlackListed" key="blackListed" />
      </Tabs>
      {renderTable()}
    </div>
  );
};

export default UserTable;
