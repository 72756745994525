import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

export const fetchConferenceInvitationDetails = createAsyncThunk(
  "conference/fetchInvitationDetails",
  async (invitationId, { rejectWithValue }) => {
    try {
      const data = JSON.stringify({ id: invitationId });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/conference/getConferenceInvitationDetails`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic Q29uZmVyUHJvQDIwMjQ6QXJjaHRlY2hAMjAyNA==",
        },
        data: data,
      };

      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchConferenceName = createAsyncThunk(
  "conference/fetchConferenceName",
  async (conferenceId, { rejectWithValue }) => {
    try {
      const data = JSON.stringify({ conferenceId: conferenceId });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/conference/getConferenceName`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic Q29uZmVyUHJvQDIwMjQ6QXJjaHRlY2hAMjAyNA==",
        },
        data: data,
      };

      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const reviewAcceptance = createAsyncThunk(
  "conference/reviewAcceptance",
  async ({ conferenceId, email, id, accept }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/conference/reviewAcceptance`,
        {
          conferenceId,
          email,
          id,
          accept,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          maxBodyLength: Infinity,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const initialState = {
  invitationDetails: null,
  conferenceDetail: null,
  loading: false,
  error: null,
};

const reviewerinvitationFlowSlice = createSlice({
  name: "conference",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConferenceInvitationDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchConferenceInvitationDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.invitationDetails = action.payload;
      })
      .addCase(fetchConferenceInvitationDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(fetchConferenceName.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchConferenceName.fulfilled, (state, action) => {
        state.loading = false;
        state.conferenceDetail = action.payload;
      })
      .addCase(fetchConferenceName.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(reviewAcceptance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(reviewAcceptance.fulfilled, (state, action) => {
        state.loading = false;
        state.conferenceDetail = action.payload;
        notification.success({
          message: "Success",
          description:
            "Review acceptance status updated successfully! Please Login again.",
        });
      })
      .addCase(reviewAcceptance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        notification.error({
          message: "Failed",
          description: "Failed to update review acceptance status",
        });
      });
  },
});

export default reviewerinvitationFlowSlice.reducer;
