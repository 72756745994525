export function convertIsoToDate(isoString) {
  const date = new Date(isoString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
}

export function capitalizeFirstLetter(str) {
  if (typeof str !== "string" || str.length === 0) {
    return "";
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function capitalizeFirstLetterStatus(str) {
  if (typeof str !== "string" || str.length === 0) {
    return "";
  }

  const cleanedStr = str.toLowerCase().replace(/_/g, " ");

  return cleanedStr
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function checkJWTValidity() {
  const JWT = localStorage.getItem("authToken");
  if (JWT === null) return false;

  const jwtPayload = JSON.parse(window.atob(JWT.split(".")[1]));
  const isExpired = Date.now() >= jwtPayload.exp * 1000;

  return !isExpired;
}
