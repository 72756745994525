import { Button, Form, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchConferenceEmailTemplates } from "../../store/slices/fetchEmailTemplatedslice";
import {
  sendEmail,
  sendEmailToReviewer,
} from "../../store/slices/sendEmailSlice";

const SendBulkEmailModal = ({
  conferenceId,
  handleSendBulkEmailModalClose,
  isSendBulkEmailModalVisible,
  userConfereceBasedOnRoleList,
  selectedIds,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const sendEmailSlice = useSelector((state) => state.sendEmail);

  const templates = useSelector(
    (state) => state.fetchConferenceEmailTemplatesSlice
  );

  useEffect(() => {
    if (conferenceId) dispatch(fetchConferenceEmailTemplates(conferenceId));
  }, [conferenceId]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        console.log("values", values);
        if (values.toWhom === "AUTHORS") {
          const data = {
            paperIds: selectedIds,
            templateId: values.template,
            conferenceId: conferenceId,
          };

          console.log("data", data);
          dispatch(sendEmail(data)).then(() => {
            handleSendBulkEmailModalClose();
            form.resetFields();
          });
        } else {
          const data = {
            paperIds: selectedIds,
            templateId: values.template,
            conferenceId: conferenceId,
            status: values.toWhom,
          };

          console.log("data", data);
          dispatch(sendEmailToReviewer(data)).then(() => {
            handleSendBulkEmailModalClose();
            form.resetFields();
          });
        }
      })
      .catch((errorInfo) => {
        console.error("Failed to submit form:", errorInfo);
      });
  };

  return (
    <Modal
      title="Send Email to Authors"
      open={isSendBulkEmailModalVisible}
      onCancel={handleSendBulkEmailModalClose}
      footer={[
        <Button key="cancel" onClick={handleSendBulkEmailModalClose}>
          Cancel
        </Button>,
        <Button
          key="send"
          type="primary"
          disabled={sendEmailSlice.loading}
          onClick={handleSubmit}
        >
          {sendEmailSlice.loading ? "Sending..." : "Send"}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Select Template"
          name="template"
          rules={[{ required: true, message: "Please select a template!" }]}
        >
          <Select placeholder="Select a template">
            {templates?.templates?.data?.map((template) => (
              <Select.Option key={template.id} value={template.id}>
                {template.templateName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="To Whom?"
          name="toWhom"
          rules={[{ required: true, message: "Please select a recipient!" }]}
        >
          <Select placeholder="To whom you want to send the email">
            <Select.Option key="ALL" value="ALL">
              All Reviewers
            </Select.Option>
            <Select.Option key="PENDING" value="PENDING">
              Pending Reviewers
            </Select.Option>
            <Select.Option key="SUBMITTED" value="SUBMITTED">
              Submitted Reviewers
            </Select.Option>
            <Select.Option key="AUTHORS" value="AUTHORS">
              Authors
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SendBulkEmailModal;
