import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

export const editReviewByChair = createAsyncThunk(
  "review/editReviewByChair",
  async (reviewData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/conference/paper/review/chair/submit`,
        reviewData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          maxBodyLength: Infinity,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const editReviewByChairSlice = createSlice({
  name: "editReviewByChairSlice",
  initialState: {
    loading: false,
    success: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(editReviewByChair.pending, (state) => {
        state.loading = true;
      })
      .addCase(editReviewByChair.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload;
        notification.success({
          message: "Success",
          description: "Review Edited successfully",
        });
      })
      .addCase(editReviewByChair.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        notification.error({
          message: "Error",
          description: action.payload.message,
        });
      });
  },
});

export default editReviewByChairSlice.reducer;
