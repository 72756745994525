import React, { useState, useEffect } from "react";
import { Modal, Table, Button, Input, Spin, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addReviewer,
  fetchReviewerInviteeInfo,
  fetchReviewers,
} from "../../store/slices/adminAddreviewerSlice";
import { checkEmail } from "../../store/slices/reviewerInviteSlice";

const AddReviewersModal = ({
  visible,
  onClose,
  conferenceId,
  setReload,
  setAddReviewerDirectlyModal,
  setAddNotDbUserDataModal,
  setEmail,
  email,
}) => {
  const dispatch = useDispatch();

  let loading = false;

  const [adding, setAdding] = useState(false);

  const handleAddReviewer = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      notification.error({ message: "Email is required." });
      return;
    }
    if (!emailRegex.test(email)) {
      notification.error({ message: "Invalid email format." });
      return;
    }
    setAdding(true);
    dispatch(checkEmail({ email, conferenceId })).then((res) => {
      console.log("res", res);
      if (res.payload.success === true) {
        setAddReviewerDirectlyModal(true);
      } else {
        setAddNotDbUserDataModal(true);
      }

      onClose();
      setAdding(false);
      setReload(Math.random());
    });
  };

  return (
    <Modal
      title="Add Reviewers"
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      {loading === true && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin />
        </div>
      )}

      {loading === false && (
        <>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Input
              placeholder="Enter reviewer email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ marginBottom: 16 }}
            />
            <Button
              type="primary"
              // disabled={email.length === 0}
              style={{
                backgroundColor: email.length === 0 ? "grey" : "#8A1E9B",
              }}
              onClick={handleAddReviewer}
              loading={adding}
            >
              Add Reviewer
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default AddReviewersModal;
