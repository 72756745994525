import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

export const fetchConferenceQuestions = createAsyncThunk(
  "conferenceQuestions/fetchQuestions",
  async (confId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/conference/${confId}/questions`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          maxBodyLength: Infinity,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

const conferenceQuestionsSlice = createSlice({
  name: "conferenceQuestions",
  initialState: {
    questions: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConferenceQuestions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchConferenceQuestions.fulfilled, (state, action) => {
        state.loading = false;
        state.questions = action.payload;
      })
      .addCase(fetchConferenceQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        notification.error({
          description: "Error fetching questions",
        });
      });
  },
});

export default conferenceQuestionsSlice.reducer;
