import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

export const toggleBlacklistUser = createAsyncThunk(
  "users/toggleBlacklistUser",
  async ({ userId, blocked }, { rejectWithValue }) => {
    try {
      const config = {
        method: "patch",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/user/blacklist?userId=${userId}&blocked=${blocked}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };

      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const assignUserRole = createAsyncThunk(
  "users/assignUserRole",
  async ({ email, type }, { rejectWithValue }) => {
    try {
      const data = JSON.stringify({ email, type });
      const config = {
        method: "patch",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/user/assign-admin`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        data: data,
      };

      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const usersListActionSlice = createSlice({
  name: "usersListActionSlice",
  initialState: {
    usersList: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(toggleBlacklistUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(toggleBlacklistUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        notification.success({
          message: "Blacklist status updated",
        });
      })
      .addCase(toggleBlacklistUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        notification.error({
          message: "Error",
          description: action.payload.error,
        });
      })
      .addCase(assignUserRole.pending, (state) => {
        state.status = "loading";
      })
      .addCase(assignUserRole.fulfilled, (state, action) => {
        state.status = "succeeded";
        notification.success({
          message: "Role assigned successfully",
        });
      })
      .addCase(assignUserRole.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        notification.error({
          message: "Error",
          description: action.payload.error,
        });
      });
  },
});

export default usersListActionSlice.reducer;
