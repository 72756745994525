import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

export const downloadConferenceSubmissions = createAsyncThunk(
  "conference/downloadSubmissions",
  async (conferenceId, { rejectWithValue }) => {
    try {
      // Make the request to get the file as a Blob
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/conference/${conferenceId}/submissions/download`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          responseType: "blob", // Important for handling binary data
          maxBodyLength: Infinity, // Ensure large files are handled
        }
      );

      // Ensure only one download link is created and clicked
      const blob = response.data;
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "submission.zip"; // Set the filename here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url); // Clean up the URL object

      return "Download initiated"; // Or any success message if needed
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

const initialState = {
  submissions: [],
  loading: false,
  error: null,
};

const downloadConferenceSubmissionsSlice = createSlice({
  name: "downloadConferenceSubmissionsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadConferenceSubmissions.pending, (state) => {
        state.loading = true;
        state.error = null;
        notification.warning({
          message: "Downloading submissions",
          description: "Please wait while we download the submissions.",
        });
      })
      .addCase(downloadConferenceSubmissions.fulfilled, (state, action) => {
        state.loading = false;
        const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "submissions.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        notification.success({
          message: "Success",
          description: "Submissions downloaded successfully.",
        });
      })
      .addCase(downloadConferenceSubmissions.rejected, (state, action) => {
        state.loading = false;
        notification.error({
          message: "Error",
          description: action.payload.error,
        });
      });
  },
});

export default downloadConferenceSubmissionsSlice.reducer;
