import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { MenuOutlined, UserOutlined, LeftOutlined } from "@ant-design/icons";
import { Avatar, Button, Drawer, Tooltip } from "antd";
import { logo } from "../assets/enumConstants";

const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState("U");
  const [color, setColor] = React.useState("");
  const pathName = location.pathname;

  const onClose = () => {
    setOpen(false);
  };

  function getRandomColor() {
    const randomIndex = Math.floor(Math.random() * ColorList.length);
    return ColorList[randomIndex];
  }

  const role = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData")).userType
    : "";

  const userobj = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : "";

  useEffect(() => {
    if (!notAllowedPath.includes(pathName) && pathName !== "/" && userobj) {
      setUser(userobj?.name?.charAt(0));
      const color = getRandomColor();
      setColor(color);
    }
  }, [userobj]);

  // Array of paths where the Navbar should be hidden
  const notAllowedPath = ["/sign-up", "/login", "/fp"];

  // Check if the current path contains `/rInvite`
  const isReviewerInvite = pathName.includes("/rInvite");

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("email");
    navigate("/");
  };

  return (
    <div>
      {/* Third Navbar for registerUser */}
      {pathName === "/registerUser" ? (
        <div className="navbar-registerUser"></div>
      ) : notAllowedPath.includes(pathName) ||
        pathName === "/" ||
        isReviewerInvite ? (
        <div className="navbar2"></div>
      ) : (
        <div className="navbar">
          <Link style={{ width: "max-content" }}>
            <img className="logo2" src={logo} alt="logo" />
          </Link>
          <div className="navbar_right">
            {pathName === "/dashboard" ? null : (
              <Button onClick={() => navigate(-1)}>
                <LeftOutlined style={{ fontSize: "15px" }} />
              </Button>
            )}
            <Tooltip title={userobj.name}>
              <Avatar size={50} style={{ backgroundColor: color }}>
                {user}
              </Avatar>
            </Tooltip>

            <Button onClick={() => setOpen(true)}>
              <MenuOutlined />
            </Button>
          </div>
          <Drawer
            className="glassmorph2"
            title="Menu"
            onClose={onClose}
            open={open}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Avatar size={60} style={{ backgroundColor: color }}>
                  {user}
                </Avatar>
                <div>
                  <h4>{userobj.name}</h4>
                  <h4>{userobj.email}</h4>
                </div>
              </div>
              <hr style={{ margin: "2rem 0" }} />
              <div className="navbar_options" onClick={() => setOpen(false)}>
                {role === "SUPER_ADMIN" || role === "ADMIN" ? (
                  <Link to="/Adashboard">Dashboard</Link>
                ) : (
                  <Link to="/dashboard">Dashboard</Link>
                )}
                {role === "SUPER_ADMIN" || role === "ADMIN" ? (
                  <Link to="/usersList">Users</Link>
                ) : null}
                <Link to="/createConference">Create Conference</Link>
                <Link style={{ cursor: "pointer" }} onClick={handleLogout}>
                  Logout
                </Link>
              </div>
            </div>
          </Drawer>
        </div>
      )}
    </div>
  );
};

export default Navbar;
