import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchConferenceDetails } from "../../store/slices/conferenceSlice";
import {
  fetchReviewerInviteeInfo,
  fetchReviewers,
  processReviewers,
} from "../../store/slices/adminAddreviewerSlice";
import excel from "./../../assets/RE.csv";
import { Button, Form, Input, Modal, Switch, Table, Upload } from "antd";
import AddReviewersModal from "./AddReviewersModal";
import {
  addReviewerInDb,
  addReviewerNotInDb,
} from "../../store/slices/reviewerInviteSlice";
import { downloadReviewerCSV } from "../../store/slices/importExportReviewersSlice";
import { UploadOutlined } from "@ant-design/icons";

const ManageReviewers = () => {
  const [form] = Form.useForm();
  const { confId } = useParams();
  const dispatch = useDispatch();
  const [isAddReviewersModalVisible, setIsAddReviewersModalVisible] =
    React.useState(false);
  const [addReviewerDirectlyModal, setAddReviewerDirectlyModal] =
    useState(false);
  const [email, setEmail] = useState("");
  const [addDirectly, setAddDirectly] = useState(false);
  const [addNotDbUserDataModal, setAddNotDbUserDataModal] = useState(false);
  const [importReviewersModal, setImportReviewersModal] = useState(false);
  const [reload, setReload] = React.useState(0);
  const [fileList, setFileList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setFileList([]);
  };

  const handleFileChange = ({ file, fileList }) => {
    if (fileList.length > 1) {
      fileList = [fileList[1]];
    }
    setFileList(fileList);
  };

  const handleSubmit = () => {
    if (fileList.length === 0) {
      return;
    }

    dispatch(
      processReviewers({
        filePath: fileList[0].originFileObj,
        conferenceId: confId,
      })
    ).then(() => {
      setFileList([]);
      setIsModalVisible(false);
      setReload(Math.random());
    });
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "ids",
      key: "ids",
      render: (text, record, index) => <a>{index + 1}</a>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <p>
          {record?.name === "" || record.name === null
            ? "Not Registered"
            : record.name}
        </p>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button type="dashed" danger>
          Remove
        </Button>
      ),
    },
  ];

  const column2 = [
    {
      title: "S.No.",
      dataIndex: "ids",
      key: "ids",
      render: (text, record, index) => <a>{index + 1}</a>,
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "name",
      render: (text, record) => (
        <p>
          {record?.Name === "" || record.Name === null
            ? "Not Registered"
            : record.Name}
        </p>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button type="dashed" danger>
          Remind
        </Button>
      ),
    },
  ];

  const reviewersState = useSelector((state) => state.reviewersSlice);

  useEffect(() => {
    dispatch(fetchConferenceDetails(confId));
    dispatch(fetchReviewers(confId));
    dispatch(fetchReviewerInviteeInfo(confId));
  }, [confId, dispatch, reload]);

  const handleDownloadReviewers = () => {
    dispatch(downloadReviewerCSV(confId));
  };

  function downloadExcelFile(filePath, fileName) {
    const link = document.createElement("a");

    link.href = filePath;
    link.download = fileName;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }

  const handleAddDirectly = () => {
    dispatch(
      addReviewerInDb({ conferenceId: confId, email, directly: true })
    ).then((res) => {
      console.log("res", res);
      setAddReviewerDirectlyModal(false);
      setEmail("");
      setReload(Math.random());
    });
  };

  const handleAddViaInvitation = () => {
    dispatch(
      addReviewerInDb({ conferenceId: confId, email, directly: false })
    ).then((res) => {
      console.log("res", res);
      setAddReviewerDirectlyModal(false);
      setEmail("");
      setReload(Math.random());
    });
  };

  const handleIfUserNotInDb = (values) => {
    console.log("values", values);
    dispatch(
      addReviewerNotInDb({
        conferenceId: confId,
        email: email,
        name: values.name,
        affiliation: values.affiliation,
        directly: addDirectly,
      })
    ).then((res) => {
      console.log("res", res);
      setAddNotDbUserDataModal(false);
      setAddDirectly(false);
      form.resetFields();
      setReload(Math.random());
    });
  };

  return (
    <div className="pageContainer">
      <div className="manageReviewers_row1">
        <h1 style={{ marginBottom: "1rem" }}>Manage Reviewers</h1>
        <div>
          <Button
            type="primary"
            style={{ marginRight: "1rem" }}
            onClick={() => handleDownloadReviewers()}
          >
            Export Reviewers
          </Button>
          <Button
            type="primary"
            onClick={() => showModal()}
            style={{ marginRight: "1rem" }}
          >
            Import Reviewers
          </Button>
          <Button
            type="primary"
            onClick={() => setIsAddReviewersModalVisible(true)}
          >
            Add Reviewers
          </Button>
        </div>
      </div>

      <Table dataSource={reviewersState.reviewers} columns={columns} />

      <h1 style={{ marginBottom: "1rem" }}>Pending Reviewers</h1>

      <Table dataSource={reviewersState.pendingReviewers} columns={column2} />

      <AddReviewersModal
        visible={isAddReviewersModalVisible}
        onClose={() => setIsAddReviewersModalVisible(false)}
        conferenceId={confId}
        setReload={setReload}
        setAddReviewerDirectlyModal={setAddReviewerDirectlyModal}
        setAddNotDbUserDataModal={setAddNotDbUserDataModal}
        setEmail={setEmail}
        email={email}
      />

      <Modal
        title="Add Reviewers"
        open={addReviewerDirectlyModal}
        onCancel={() => setAddReviewerDirectlyModal(false)}
        footer={
          <>
            <Button type="primary" onClick={() => handleAddDirectly()}>
              Add Directly
            </Button>
            <Button type="primary" onClick={() => handleAddViaInvitation()}>
              Add via Invitation
            </Button>
          </>
        }
      >
        <p>
          Would you like to send an invitation to this reviewer to join your
          conference?
        </p>
      </Modal>

      <Modal
        title="Add Reviewers"
        open={addNotDbUserDataModal}
        onCancel={() => setAddNotDbUserDataModal(false)}
        footer={null}
      >
        <p>
          The user you are trying to add is not registered with us. Please add
          the General Information to invite the user.
        </p>

        <Form form={form} layout="vertical" onFinish={handleIfUserNotInDb}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: "Please enter the reviewer's name" },
            ]}
          >
            <Input placeholder="Enter reviewer's name" />
          </Form.Item>

          <Form.Item label="Email" name="email">
            <Input type="email" disabled placeholder={email} />
          </Form.Item>

          <Form.Item
            label="Affiliation"
            name="affiliation"
            rules={[
              { required: true, message: "Please enter the affiliation" },
            ]}
          >
            <Input placeholder="Enter affiliation" />
          </Form.Item>

          <Form.Item
            label="Add Directly"
            valuePropName="checked"
            tooltip="Toggle this if you want to add the reviewer directly without sending an invitation"
          >
            <Switch checked={addDirectly} onChange={setAddDirectly} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {addDirectly ? "Add Reviewer Directly" : "Send Invitation"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Import Reviewers CSV"
        open={importReviewersModal}
        onCancel={() => setImportReviewersModal(false)}
        footer={null}
      >
        <p>
          To import reviewers, please upload a CSV file containing the reviewer
          information.
        </p>
        <Upload accept=".csv">
          <Button type="primary">Upload CSV</Button>
        </Upload>
      </Modal>

      <Modal
        title="Upload Reviewers CSV"
        visible={isModalVisible}
        onOk={handleSubmit}
        onCancel={handleCancel}
        // confirmLoading={loading}
        okText={"Upload"}
        cancelText="Cancel"
      >
        <p>
          To download the template below, please fill it out and upload it in
          the required format to submit bulk reviewers.
        </p>
        <p>
          Important: While filling the sheet, please note that the "Directly"
          column expects a Y/N value. Selecting "Y" confirms that you agree to
          add the reviewer directly.
        </p>

        <a href={excel} download="Bulk Reviewer Template.csv">
          Download CSV
        </a>

        <p style={{ marginTop: "1rem" }}>Upload File</p>

        <Upload
          beforeUpload={() => false}
          onChange={handleFileChange}
          fileList={fileList}
        >
          <Button icon={<UploadOutlined />}>Select CSV File</Button>
        </Upload>
      </Modal>
    </div>
  );
};

export default ManageReviewers;
