import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  message,
  notification,
  Tooltip,
  Tag,
  Spin,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { createEmailTemplate } from "../../store/slices/emailTemplateAddSlice";

const placeholders = [
  "{{conferenceHandle}}",
  "{{authorName}}",
  "{{paperName}}",
  "{{conferenceName}}",
  "{{paperId}}",
  "{{trackName}}",
  "{{reviews}}",
];

const CreateEmailTemplate = ({ confId, setReload }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.emailTemplateCreate.loading);

  const onFinish = (values) => {
    dispatch(
      createEmailTemplate({
        templateName: values.templateName,
        subject: values.subject,
        templateBody: values.templateBody,
        conferenceId: confId,
      })
    ).then((response) => {
      if (response.payload.success) {
        form.resetFields();
        setReload(Math.random());
      }
    });
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(`${text} `).then(
      () => {
        notification.success({
          message: "Copied to clipboard",
          description: text,
        });
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
  };

  if (loading) {
    return (
      <div className="fullPageLoading">
        <Spin tip="Loading..." />
      </div>
    );
  }

  return (
    <div style={{ marginTop: "3rem" }}>
      <Form
        form={form}
        layout="vertical"
        style={{
          justifyContent: "center",
          gap: "1rem",
          display: "flex",
          flexDirection: "column",
        }}
        onFinish={onFinish}
      >
        <Form.Item
          style={{ textAlign: "center" }}
          name="templateName"
          rules={[
            { required: true, message: "Please enter the template name!" },
          ]}
        >
          <Input
            placeholder="Your Template Title"
            type="text"
            style={{
              border: "1px solid black",
            }}
            className="templateInput"
          />
        </Form.Item>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: ".5rem",
          }}
        >
          <p style={{ marginRight: "8px" }}>Available Placeholders:</p>
          <Tooltip
            title={
              <div className="emailTemplate_placeHolders_info">
                <p style={{ margin: 0 }}>Click a placeholder to copy it.</p>
                <p style={{ margin: 0 }}>
                  It will be replaced with the corresponding value in your
                  email.
                </p>
              </div>
            }
          >
            <InfoCircleOutlined
              style={{ fontSize: "16px", color: "#1890ff" }}
            />
          </Tooltip>
        </div>

        <div className="emailTemplate_placeHolders">
          {placeholders.map((placeholder, index) => (
            <Tag
              key={index}
              className="emailTemplate_placeHolders_tag"
              onClick={() => handleCopyToClipboard(placeholder)}
            >
              {placeholder}
            </Tag>
          ))}
        </div>

        <Form.Item
          name="subject"
          label="Email Subject"
          style={{ margin: "1rem 0", flex: 1 }}
          rules={[
            { required: true, message: "Please enter the email subject!" },
          ]}
        >
          <Input placeholder="Enter Email Subject" />
        </Form.Item>

        <Form.Item
          label="Template Body"
          name="templateBody"
          rules={[{ required: true, message: "Please enter the email body!" }]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create Template
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateEmailTemplate;
