import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

export const sendEmail = createAsyncThunk(
  "conference/sendEmail",
  async (data, thunkAPI) => {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/conference/author/email/send`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const downloadSelectedSubmissions = createAsyncThunk(
  "submissions/downloadSelectedSubmissions",
  async ({ paperIds, conferenceId }, { rejectWithValue }) => {
    try {
      const data = JSON.stringify({ paperIds });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/conference/${conferenceId}/selected/submissions/download`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        responseType: "blob", // Important for handling binary data
        maxBodyLength: Infinity, // Ensure large files are handled
        data: data,
      };

      const response = await axios.request(config);
      const blob = response.data;
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "submission.zip"; // Set the filename here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url); // Clean up the URL object
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const sendEmailToReviewer = createAsyncThunk(
  "email/sendEmailToReviewer",
  async (
    { templateId, paperIds, conferenceId, status },
    { rejectWithValue }
  ) => {
    try {
      const data = JSON.stringify({
        templateId,
        paperIds,
        status,
        conferenceId,
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/conference/reviewer/email/send`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        data: data,
      };

      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const emailSlice = createSlice({
  name: "email",
  initialState: {
    loading: false,
    error: null,
    response: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.response = null;
        notification.info({
          message: "Sending email...",
        });
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
        state.error = null;
        notification.success({
          message: "Email sent successfully!",
        });
      })
      .addCase(sendEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.response = null;
        notification.error({
          message: "Failed to send email",
          description: action.payload.error,
        });
      });

    builder
      .addCase(sendEmailToReviewer.pending, (state) => {
        state.loading = true;
        state.error = null;
        notification.info({
          message: "Sending email...",
        });
      })
      .addCase(sendEmailToReviewer.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
        state.error = null;
        notification.success({
          message: "Email sent successfully!",
        });
      })
      .addCase(sendEmailToReviewer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.response = null;
        notification.error({
          message: "Failed to send email",
          description: action.payload.error,
        });
      });
    builder
      .addCase(downloadSelectedSubmissions.pending, (state) => {
        state.loading = true;
        state.error = null;
        notification.info({
          message: "Downloading Submissions...",
        });
      })
      .addCase(downloadSelectedSubmissions.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
        state.error = null;
        notification.success({
          message: "Downloaded successfully!",
        });
      })
      .addCase(downloadSelectedSubmissions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.response = null;
        console.log("action.payload", action.payload);
        notification.error({
          message: "Failed to download file.",
          // description: action.,
        });
      });
  },
});

export default emailSlice.reducer;
