import { Modal, Spin } from "antd";
import React, { useEffect } from "react";
import { fetchReviews } from "../../store/slices/fetchReviewsSlice";
import { useDispatch, useSelector } from "react-redux";

const ViewReviewModal = ({ visible, onClose, record, role }) => {
  console.log("reviews123", record);
  const [filteredData, setFilteredData] = React.useState(null);
  const dispatch = useDispatch();
  const fetchReviewsSlice = useSelector((state) => state?.fetchReviewsSlice);

  useEffect(() => {
    if (record?.paperId) dispatch(fetchReviews(record?.paperId));
  }, [record]);

  useEffect(() => {
    if (fetchReviewsSlice?.reviews?.length > 0 && record?.id) {
      const filteredData = fetchReviewsSlice?.reviews?.filter(
        (item) => item.id === record.id
      );
      setFilteredData(filteredData);
    }
  }, [fetchReviewsSlice, record]);

  const reviewData = filteredData?.[0];
  console.log("reviewData", reviewData);

  return (
    <Modal title="Reviews" open={visible} onCancel={onClose} footer={null}>
      {fetchReviewsSlice?.loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin />
        </div>
      ) : (
        <div style={{ maxHeight: "60vh", overflowY: "scroll" }}>
          {role === "a" ? null : (
            <div
              style={{ display: "flex", alignItems: "center", gap: ".5rem" }}
            >
              <h4>Reviewer name: </h4>
              <span>{record?.reviewer?.name}</span>
            </div>
          )}
          <p style={{ margin: "1rem 0" }}>
            <strong>Verdict:</strong> {reviewData?.verdict}
          </p>
          {reviewData?.answers?.map((question, index) => (
            <div key={index} style={{ marginBottom: "20px" }}>
              <h3>{`Q${index + 1}: ${question?.question?.text}`}</h3>
              {question.question.type === "MULTIPLE_CHOICE" ? (
                <p>{question?.option?.text}</p>
              ) : (
                <p>{question?.answer}</p>
              )}
            </div>
          ))}

          <p>
            <strong>Comments:</strong> {reviewData?.comment}
          </p>
        </div>
      )}
    </Modal>
  );
};

export default ViewReviewModal;
