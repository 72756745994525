import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Login from "./pages/loginSignup/Login";
import RegistrationUserForm from "./pages/loginSignup/RegistrationUserForm";
import Dashboard from "./pages/Dashboard/Dashboard";
import ConferenceDetails from "./pages/conferenceDetail/ConferenceDetail";
import CreateNewSubmission from "./pages/conferenceDetail/CreateNewSubmission";
import Navbar from "./components/Navbar";
import CreateConference from "./pages/Dashboard/CreateConference";
import AdminDashboard from "./pages/adminDashboard/AdminDashboard";
import AdminUsersList from "./pages/AdminUsers/AdminUsersList";
import ConferenceSettings from "./pages/ConferenceSetting/ConferenceSettings";
import ConferenceQuestionair from "./pages/ConferenceSetting/ConferenceQuestionair";
import EmailTemplateSetting from "./pages/emailTemplates/EmailTemplateSetting";
import PaperDetails from "./pages/conferenceDetail/PaperDetails";
import EnterReview from "./pages/conferenceDetail/EnterReview";
import EditReviewByChair from "./pages/conferenceDetail/EditReviewByChair";
import ForgotPassword from "./pages/loginSignup/ForgotPassword";
import ManageReviewers from "./pages/conferenceDetail/ManageReviewers";
import ReviewerInvitation from "./pages/Invitations/ReviewerInvitation";

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("authToken");
  const location = useLocation();

  if (!token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

const AppLayout = () => {
  const location = useLocation();
  const requiresPageContainer = ["/", "/fp", "/rInvite/:id"].some(
    (path) =>
      path === location.pathname ||
      (path.includes(":") &&
        location.pathname.startsWith(path.replace(":id", "")))
  );

  return (
    <div className={requiresPageContainer ? "pageContainer" : "pageContainer2"}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/fp" element={<ForgotPassword />} />
        <Route path="/rInvite/:id" element={<ReviewerInvitation />} />

        <Route
          path="/registerUser"
          element={
            <ProtectedRoute>
              <RegistrationUserForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Adashboard"
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/conferenceDetail/:confId"
          element={
            <ProtectedRoute>
              <ConferenceDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/conferenceQuestions/:confId"
          element={
            <ProtectedRoute>
              <ConferenceQuestionair />
            </ProtectedRoute>
          }
        />
        <Route
          path="/conferenceSetting/:confId"
          element={
            <ProtectedRoute>
              <ConferenceSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/createSubmission"
          element={
            <ProtectedRoute>
              <CreateNewSubmission />
            </ProtectedRoute>
          }
        />
        <Route
          path="/paperDetails"
          element={
            <ProtectedRoute>
              <PaperDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/createConference"
          element={
            <ProtectedRoute>
              <CreateConference />
            </ProtectedRoute>
          }
        />
        <Route
          path="/usersList"
          element={
            <ProtectedRoute>
              <AdminUsersList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/emailTemplates/:confId"
          element={
            <ProtectedRoute>
              <EmailTemplateSetting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/review/create/:paperId"
          element={
            <ProtectedRoute>
              <EnterReview />
            </ProtectedRoute>
          }
        />
        <Route
          path="/review/editByChair/:confId"
          element={
            <ProtectedRoute>
              <EditReviewByChair />
            </ProtectedRoute>
          }
        />
        <Route
          path="/manageReviewers/:confId"
          element={
            <ProtectedRoute>
              <ManageReviewers />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <AppLayout />
      </BrowserRouter>
    </div>
  );
}

export default App;
