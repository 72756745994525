import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

export const createEmailTemplate = createAsyncThunk(
  "emailTemplate/create",
  async (
    { templateName, subject, templateBody, conferenceId },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/conference/email/template`,
        {
          templateName,
          subject,
          templateBody,
          conferenceId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create a slice
const emailTemplateSlice = createSlice({
  name: "emailTemplate",
  initialState: {
    loading: false,
    error: null,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createEmailTemplate.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(createEmailTemplate.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        notification.success({
          message: "Success",
          description: "Email template created successfully.",
        });
      })
      .addCase(createEmailTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
        notification.error({
          message: "Error",
          description: action.payload.error,
        });
      });
  },
});

export default emailTemplateSlice.reducer;
