import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

export const fetchConferenceTemplates = createAsyncThunk(
  "conferenceTemplates/fetchConferenceTemplates",
  async (conferenceId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/conference/${conferenceId}/templates`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          maxBodyLength: Infinity,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error fetching templates"
      );
    }
  }
);

export const deleteConferenceEmailTemplate = createAsyncThunk(
  "conferenceTemplates/deleteConferenceEmailTemplate",
  async (templateId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/conference/email/template/${templateId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          maxBodyLength: Infinity,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error deleting template");
    }
  }
);

const emailTemplateFetchSlice = createSlice({
  name: "conferenceTemplates",
  initialState: {
    templates: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConferenceTemplates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchConferenceTemplates.fulfilled, (state, action) => {
        state.loading = false;
        state.templates = action.payload.data;
      })
      .addCase(fetchConferenceTemplates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        notification.error({
          message: "Error",
          description: "Error fetching templates",
        });
      });
    builder
      .addCase(deleteConferenceEmailTemplate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteConferenceEmailTemplate.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteConferenceEmailTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        notification.error({
          message: "Error",
          description: action.payload.error,
        });
      });
  },
});

export default emailTemplateFetchSlice.reducer;
