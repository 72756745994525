import { Modal } from "antd";
import React from "react";

const EditSubmissionModal = ({ visible, onClose, record, setReload }) => {
  return (
    <Modal
      title="Edit Submission"
      open={visible}
      onOk={onClose}
      onCancel={onClose}
    >
      {console.log(JSON.stringify(record, null, 2))}
      <p>Submission ID: {record?.externalRefId}</p>
      <p>Submission Title: {record?.title}</p>
      <p>Submission Status: {record?.status}</p>
    </Modal>
  );
};

export default EditSubmissionModal;
