import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

export const deleteConferenceQuestion = createAsyncThunk(
  "conference/deleteQuestion",
  async ({ conferenceId, questionId, activeStatus }, thunkAPI) => {
    const url = `${process.env.REACT_APP_API_URL}/conference/question/${questionId}/active?status=${activeStatus}`;

    try {
      const response = await axios.post(
        url,
        {
          conferenceId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          maxBodyLength: Infinity,
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const deleteQuestionSlice = createSlice({
  name: "deleteQuestionSlice",
  initialState: {
    loading: false,
    error: null,
    success: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteConferenceQuestion.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(deleteConferenceQuestion.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload;
        notification.success({
          message: "Question deleted successfully",
          description: "The question has been deleted successfully",
        });
      })
      .addCase(deleteConferenceQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        notification.error({
          message: "Failed to delete question",
          description: action.payload.error,
        });
      });
  },
});

export default deleteQuestionSlice.reducer;
