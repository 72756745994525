import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Spin, Tabs } from "antd";
import { fetchAllConferenceAdminList } from "../../store/slices/allConferenceAdminSlice";
import { changeConferenceStatus } from "../../store/slices/changeConfApprovedSlice";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState("PENDING");
  const [loadingConferenceId, setLoadingConferenceId] = useState(null);
  const pageSize = 10;

  const allConferenceAdminList = useSelector(
    (state) => state.allConferenceAdminList
  );
  const changeConferenceStatusState = useSelector(
    (state) => state.changeConferenceStatus
  );

  useEffect(() => {
    dispatch(
      fetchAllConferenceAdminList({
        page,
        pageSize,
        approvalStatus: statusFilter,
      })
    );
  }, [dispatch, page, pageSize, statusFilter]);

  const handleTabChange = (key) => {
    setStatusFilter(key);
    setPage(1);
  };

  const handleChangeStatus = (conferenceId, approved) => {
    setLoadingConferenceId(conferenceId);
    dispatch(changeConferenceStatus({ conferenceId, approved })).then(() => {
      setLoadingConferenceId(null);
      dispatch(
        fetchAllConferenceAdminList({
          page: 1,
          pageSize,
          approvalStatus: statusFilter,
        })
      );
    });
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Handle",
      dataIndex: "handle",
      key: "handle",
    },
    {
      title: "Website URL",
      dataIndex: "websiteUrl",
      key: "websiteUrl",
      render: (website) => (
        <a
          className="conferenceLink"
          href={website}
          target="_blank"
          rel="noreferrer"
        >
          Link
        </a>
      ),
    },
    {
      title: "Affiliation Name",
      dataIndex: "affiliationName",
      key: "affiliationName",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Event Type",
      dataIndex: "eventType",
      key: "eventType",
    },
    {
      title: "Approved",
      dataIndex: "approved",
      key: "approved",
      render: (approved) => (approved ? "Yes" : "No"),
    },
    {
      title: "Start Date",
      dataIndex: ["conferenceSettings", "startDate"],
      key: "startDate",
      render: (startDate) => new Date(startDate).toLocaleDateString(),
    },
    {
      title: "End Date",
      dataIndex: ["conferenceSettings", "endDate"],
      key: "endDate",
      render: (endDate) => new Date(endDate).toLocaleDateString(),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          {record.approvalStatus === "REJECTED" ? (
            <Button
              type="primary"
              onClick={() => handleChangeStatus(record.id, true)}
              disabled={loadingConferenceId === record.id}
            >
              {loadingConferenceId === record.id &&
              changeConferenceStatusState.loading ? (
                <Spin />
              ) : (
                "Accept"
              )}
            </Button>
          ) : record.approvalStatus === "PENDING" ? (
            <div style={{ display: "flex", gap: "1rem" }}>
              <Button
                type="primary"
                onClick={() => handleChangeStatus(record.id, true)}
                disabled={loadingConferenceId === record.id}
              >
                {loadingConferenceId === record.id &&
                changeConferenceStatusState.loading ? (
                  <Spin />
                ) : (
                  "Accept"
                )}
              </Button>
              <Button
                style={{ backgroundColor: "red", color: "white" }}
                onClick={() => handleChangeStatus(record.id, false)}
                disabled={loadingConferenceId === record.id}
              >
                {loadingConferenceId === record.id &&
                changeConferenceStatusState.loading ? (
                  <Spin />
                ) : (
                  "Reject"
                )}
              </Button>
            </div>
          ) : record.approvalStatus === "APPROVED" ? (
            <Button
              style={{ backgroundColor: "red", color: "white" }}
              onClick={() => handleChangeStatus(record.id, false)}
              disabled={loadingConferenceId === record.id}
            >
              {loadingConferenceId === record.id &&
              changeConferenceStatusState.loading ? (
                <Spin />
              ) : (
                "Reject"
              )}
            </Button>
          ) : null}
        </>
      ),
    },
  ];

  if (allConferenceAdminList.status === "loading") {
    return (
      <div className="fullPageLoading">
        <Spin tip="Loading..." />
      </div>
    );
  }

  return (
    <div className="pageContainer">
      <h3>Admin Dashboard</h3>
      <Tabs activeKey={statusFilter} onChange={handleTabChange}>
        <Tabs.TabPane tab="Pending" key="PENDING" />
        <Tabs.TabPane tab="Approved" key="APPROVED" />
        <Tabs.TabPane tab="Rejected" key="REJECTED" />
      </Tabs>
      <Table
        columns={columns}
        dataSource={allConferenceAdminList?.allConferenceList?.conferenceinfo}
        rowKey="id"
        expandable={{
          expandedRowRender: (record) => (
            <p style={{ margin: 0 }}>{record.description}</p>
          ),
          rowExpandable: (record) => record.description !== undefined,
        }}
        pagination={{
          current: page,
          pageSize,
          total: allConferenceAdminList?.total,
          onChange: (page) => setPage(page),
        }}
      />
    </div>
  );
};

export default AdminDashboard;
