import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchpaperSubmissionDetails } from "../../store/slices/paperInfoSlice";
import { fetchQuestions } from "../../store/slices/fetchQuestionsSlice";
import { Form, Input, Select, Button, Radio, message, Spin } from "antd";
import { addSubmitReview } from "../../store/slices/addReviewSlice";
import { reviewerVerdict } from "../../assets/enumConstants";

const { TextArea } = Input;

const EnterReview = () => {
  const { paperId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reviewId } = useLocation().state;

  const [answers, setAnswers] = useState({});
  const [verdict, setVerdict] = useState("");
  const [comment, setComment] = useState("");

  const submissionData2 = useSelector((state) => state.paperInfoState);
  const submissionData = submissionData2?.paperSubmissionDetails;
  const questions = useSelector((state) => state.questionslist);
  const addReviewstate = useSelector((state) => state.addReviewSlice);

  useEffect(() => {
    dispatch(fetchpaperSubmissionDetails(paperId));
  }, [paperId]);

  useEffect(() => {
    if (submissionData?.conferenceId) {
      dispatch(fetchQuestions(submissionData?.conferenceId));
    }
  }, [submissionData]);

  const handleAnswerChange = (questionId, value) => {
    setAnswers((prev) => ({ ...prev, [questionId]: value }));
  };

  const handleSubmit = () => {
    const answerArray = Object.entries(answers).map(([questionId, answer]) => ({
      questionId,
      answer,
    }));

    dispatch(
      addSubmitReview({
        reviewId: reviewId.id,
        conferenceId: submissionData?.conferenceId,
        verdict,
        comment,
        answers: answerArray,
      })
    )
      .unwrap()
      .then(() => {
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error submitting review:", error);
      });
  };

  if (questions.loading) {
    return (
      <div className="fullPageLoading">
        <Spin />
      </div>
    );
  }

  return (
    <div className="pageContainer">
      <h1>New Review</h1>
      <div className="newReview_row1_detail">
        <h4>Paper Name:</h4> <span>{submissionData?.paperName}</span>
      </div>
      <div className="newReview_row1_detail">
        <h4>Paper Id:</h4> <span>{submissionData?.externalRefId}</span>
      </div>

      <Form layout="vertical" onFinish={handleSubmit}>
        {questions?.data?.map((question) => (
          <Form.Item
            key={question.id}
            label={question.text}
            rules={[
              { required: true, message: `Please answer ${question.text}` },
            ]}
          >
            {question?.type === "MULTIPLE_CHOICE" ? (
              <Radio.Group
                onChange={(e) =>
                  handleAnswerChange(question.id, e.target.value)
                }
              >
                {question.options.map((option) => (
                  <Radio key={option.id} value={option.id}>
                    {option.text}
                  </Radio>
                ))}
              </Radio.Group>
            ) : (
              <Input
                onChange={(e) =>
                  handleAnswerChange(question.id, e.target.value)
                }
              />
            )}
          </Form.Item>
        ))}

        <Form.Item
          label="Comment"
          rules={[{ required: true, message: "Please enter your comment" }]}
        >
          <TextArea
            rows={4}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label="Verdict"
          rules={[{ required: true, message: "Please select a verdict" }]}
        >
          <Select value={verdict} onChange={(value) => setVerdict(value)}>
            {reviewerVerdict.map((verdict) => (
              <Select.Option key={verdict.value} value={verdict.value}>
                {verdict.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          {addReviewstate.loading ? (
            <Button type="primary" disabled>
              <Spin />
            </Button>
          ) : (
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default EnterReview;
