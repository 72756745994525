import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

export const createConference = createAsyncThunk(
  "conference/createConference",
  async ({ payload, navigate }, { rejectWithValue }) => {
    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/conference/create`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
        data: JSON.stringify(payload),
      };

      const response = await axios.request(config);
      navigate(`/dashboard`);
      return response.data;
    } catch (error) {
      notification.error({
        message: "Error",
        description: "An unexpected error occurred.",
      });
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const conferenceSlice = createSlice({
  name: "createConference",
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createConference.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createConference.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        notification.success({
          message: "Success",
          description: "Conference created successfully.",
        });
      })
      .addCase(createConference.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        notification.error({
          message: "Error",
          description: action.payload.error,
        });
      });
  },
});

export default conferenceSlice.reducer;
