import { Modal, notification, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchReviewers } from "../../store/slices/adminAddreviewerSlice";
import { assignReviewer } from "../../store/slices/assignReviewerSlice";

const AssignReviewerModal = ({
  visible,
  onClose,
  record,
  conferenceId,
  setReload,
}) => {
  const dispatch = useDispatch();
  const reviewersState = useSelector((state) => state.reviewersSlice);
  const assignReviewerstate = useSelector((state) => state.assignReviewerSlice);

  console.log("assignReviewerstate", assignReviewerstate);
  const { reviewers } = reviewersState;

  const [selectedReviewer, setSelectedReviewer] = useState(null);

  const onClose2 = () => {
    setSelectedReviewer(null);
    onClose();
  };

  useEffect(() => {
    if (visible) {
      dispatch(fetchReviewers(conferenceId));
    }
  }, [conferenceId, visible]);

  const handleSave = () => {
    if (!selectedReviewer) {
      notification.error({ message: "Please select a reviewer." });
      return;
    }
    dispatch(
      assignReviewer({ paperId: record, reviewerId: selectedReviewer })
    ).then(() => {
      setReload(Math.random());
      onClose();
    });
  };

  return (
    <Modal
      title="Assign Reviewer"
      open={visible}
      onOk={handleSave}
      onCancel={onClose2}
    >
      {assignReviewerstate?.loading || reviewersState?.loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin />
        </div>
      ) : (
        <Select
          placeholder="Select a Reviewer"
          style={{ width: "100%" }}
          onChange={setSelectedReviewer}
          value={selectedReviewer}
        >
          {reviewers?.map((reviewer) => (
            <Select.Option key={reviewer?.id} value={reviewer?.id}>
              {reviewer?.name ?? reviewer?.email}
            </Select.Option>
          ))}
        </Select>
      )}
    </Modal>
  );
};

export default AssignReviewerModal;
