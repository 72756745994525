import React, { useEffect } from "react";
import { logo } from "../../assets/enumConstants";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchConferenceInvitationDetails,
  fetchConferenceName,
} from "../../store/slices/reviewerInvitationFlowSlice";
import { Button, Spin } from "antd";
import { reviewAcceptance } from "../../store/slices/reviewerInviteSlice";

const ReviewerInvitation = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const invitaionDetails = useSelector(
    (state) => state.reviewerinvitationFlowSlice
  );

  console.log("invitaionDetails", invitaionDetails);

  useEffect(() => {
    dispatch(fetchConferenceInvitationDetails(id)).then((res) => {
      if (res?.payload?.success === true) {
        dispatch(fetchConferenceName(res?.payload?.data?.conferenceId)).then(
          (res) => {
            console.log("res", res);
          }
        );
      }
    });
  }, []);

  const handleAcceptButton = () => {
    dispatch(
      reviewAcceptance({
        id: id,
        accept: true,
      })
    ).then((res) => {
      dispatch(fetchConferenceInvitationDetails(id)).then((res) => {
        navigate("/");
      });
    });
  };

  const handleDeclineButton = () => {
    dispatch(
      reviewAcceptance({
        chairId: invitaionDetails?.invitaionDetails?.chairId,
        id: id,
        accept: false,
      })
    ).then((res) => {
      dispatch(fetchConferenceInvitationDetails(id)).then((res) => {
        navigate("/");
      });
    });
  };

  if (invitaionDetails.loading) {
    return (
      <div className="fullPageLoading">
        <Spin tip="Loading..." />
      </div>
    );
  }

  return (
    <div className="loginFormOuterContainer">
      <div className="loginFormInnerContainer neomorphism">
        <div className="loginFormInnerContainer_heading">
          <img className="logo" src={logo} alt="logo" />

          {invitaionDetails?.error?.success === false ||
          invitaionDetails?.invitationDetails?.data?.Status === "REJECTED" ? (
            <p
              style={{
                height: "100%",
                marginTop: "4rem",
              }}
            >
              Invitation Link Expired
            </p>
          ) : (
            <>
              <h1>Invitation</h1>

              <p style={{ marginTop: "4rem" }}>
                You have been invited to be a Reviewer at{" "}
                {invitaionDetails?.conferenceDetail?.data?.confer}
              </p>

              <div style={{ display: "flex", gap: "1rem", marginTop: "2rem" }}>
                <Button
                  type="primary"
                  block
                  onClick={() => {
                    handleAcceptButton();
                  }}
                >
                  Accept
                </Button>
                <Button
                  type="primary"
                  block
                  onClick={() => handleDeclineButton()}
                >
                  Decline
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReviewerInvitation;
