import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const createSubmissionSetting = createAsyncThunk(
  "submissionSettings/createSubmissionSetting",
  async ({ data, confId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/conference/${confId}/submission/setting/create`,
        { settings: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          maxBodyLength: Infinity,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "Something went wrong");
    }
  }
);

const submissionSettingsSlice = createSlice({
  name: "submissionSettings",
  initialState: {
    settings: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSubmissionSetting.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSubmissionSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.settings.push(action.payload);
      })
      .addCase(createSubmissionSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default submissionSettingsSlice.reducer;
