import { Button, Form, Modal, notification, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchConferenceEmailTemplates } from "../../store/slices/fetchEmailTemplatedslice";
import { sendEmail } from "../../store/slices/sendEmailSlice";

const SendSingleEmailModal = ({
  isSendSingleEmailModalVisible,
  handleSendSingleEmailModalClose,
  conferenceId,
  paperid,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const templates = useSelector(
    (state) => state.fetchConferenceEmailTemplatesSlice
  );
  const sendEmailState = useSelector((state) => state.sendEmail);

  console.log("templates", templates);

  useEffect(() => {
    if (conferenceId) dispatch(fetchConferenceEmailTemplates(conferenceId));
  }, [conferenceId]);

  const handleSubmit = () => {
    if (!selectedTemplate) {
      notification.error({
        message: "Please select a template!",
      });
      return;
    }
    const data = {
      paperIds: [paperid],
      templateId: selectedTemplate,
      conferenceId: conferenceId,
    };
    dispatch(sendEmail(data)).then(() => {
      handleSendSingleEmailModalClose();
    });
  };

  return (
    <Modal
      title="Send Email to Authors"
      open={isSendSingleEmailModalVisible}
      onCancel={handleSendSingleEmailModalClose}
      footer={
        templates?.loading || sendEmailState.loading
          ? null
          : [
              <Button key="cancel" onClick={handleSendSingleEmailModalClose}>
                Cancel
              </Button>,
              <Button key="send" type="primary" onClick={handleSubmit}>
                Send
              </Button>,
            ]
      }
    >
      <Form form={form} layout="vertical">
        {templates?.loading || sendEmailState.loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Spin />
          </div>
        ) : (
          <Form.Item
            label="Select Email Template"
            name="Template"
            rules={[
              { required: true, message: "Please select a Email Template!" },
            ]}
          >
            <Select
              placeholder="Select a Template"
              onChange={(value) => setSelectedTemplate(value)}
            >
              {templates?.templates?.data?.map((template) => {
                return (
                  <Select.Option key={template.id} value={template.id}>
                    {template.templateName}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default SendSingleEmailModal;
