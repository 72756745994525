import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

export const sendReviewReminderEmail = createAsyncThunk(
  "reminder/sendReminderEmail",
  async (reviewId, thunkAPI) => {
    try {
      const response = await axios({
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/conference/review/${reviewId}/reminder/email`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "Something went wrong"
      );
    }
  }
);

const sendReviewReminderSlice = createSlice({
  name: "sendReviewReminderSlice",
  initialState: {
    loading: false,

    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendReviewReminderEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
        notification.info({
          message: "Info",
          description: "Sending reminder email...",
        });
      })
      .addCase(sendReviewReminderEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        notification.success({
          message: "Success",
          description: "Reminder email sent successfully!",
        });
      })
      .addCase(sendReviewReminderEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        notification.error({
          message: "Error",
          description: action.payload.error || "Something went wrong",
        });
      });
  },
});

export default sendReviewReminderSlice.reducer;
