import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchReviews } from "../../store/slices/fetchReviewsSlice";
import { Form, Input, Radio, Spin, Button, Select, Switch } from "antd";
import { fetchQuestions } from "../../store/slices/fetchQuestionsSlice";
import { reviewerVerdict } from "../../assets/enumConstants";
import { editReviewByChair } from "../../store/slices/EditReviewByChairSlice";

const { TextArea } = Input;

const EditReviewByChair = () => {
  const { confId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { record } = useLocation().state;
  const [filteredData, setFilteredData] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [verdict, setVerdict] = useState("");
  const [comment, setComment] = useState("");
  const [sendNotification, setSendNotification] = useState(true);

  console.log("record chair", record);

  const fetchReviewsSlice = useSelector((state) => state.fetchReviewsSlice);
  const questions = useSelector((state) => state.questionslist);
  const editReviewByChairSlice = useSelector(
    (state) => state.editReviewByChairSlice
  );

  console.log("fetchReviewsSlice", fetchReviewsSlice);

  useEffect(() => {
    if (record?.paperId) dispatch(fetchReviews(record?.paperId));
    if (!record) navigate(-1);
  }, [record]);

  useEffect(() => {
    dispatch(fetchQuestions(confId));
  }, [confId]);

  useEffect(() => {
    if (fetchReviewsSlice?.reviews?.length > 0 && record?.id) {
      const filteredData = fetchReviewsSlice.reviews?.filter(
        (item) => item.id === record.id
      );
      setFilteredData(filteredData);
    }
  }, [fetchReviewsSlice, record]);

  useEffect(() => {
    if (filteredData?.length > 0) {
      const firstReview = filteredData[0];
      setAnswers(
        firstReview?.answers
          ?.filter((ans) =>
            questions?.data?.some((q) => q.id === ans.questionId)
          )
          .map((ans) => ({
            questionId: ans.questionId,
            answer: ans.answer || ans.optionId,
          }))
      );
      setVerdict(firstReview?.verdict || "");
      setComment(firstReview?.comment || "");
    }
  }, [filteredData, questions]);

  const handleAnswerChange = (questionId, value) => {
    setAnswers((prevAnswers) => {
      const existingAnswerIndex = prevAnswers.findIndex(
        (ans) => ans.questionId === questionId
      );
      if (existingAnswerIndex !== -1) {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[existingAnswerIndex].answer = value;
        return updatedAnswers;
      }
      return [...prevAnswers, { questionId, answer: value }];
    });
  };

  const handleSubmit = () => {
    const dataToSubmit = {
      reviewId: filteredData[0].id,
      conferenceId: questions?.conferenceId,
      verdict,
      comment,
      answers: answers
        .filter((ans) => questions?.data.some((q) => q.id === ans.questionId))
        .map((ans) => ({
          questionId: ans.questionId,
          answer: ans.answer,
        })),
      sendEditNotificationToReviewer: sendNotification,
    };

    dispatch(editReviewByChair(dataToSubmit)).then(() => {
      navigate(-1);
    });
  };

  if (fetchReviewsSlice.loading)
    return (
      <div className="fullPageLoading">
        <Spin tip="Loading..." />
      </div>
    );

  return (
    <div
      className="pageContainer"
      style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
    >
      <h3>
        Reviewer Name: <span> {record.reviewer?.name} </span>
      </h3>
      <h2>Edit Review</h2>
      <Form layout="vertical" onFinish={handleSubmit}>
        {questions?.data?.map((question) => {
          const existingAnswer = answers.find(
            (ans) => ans.questionId === question.id
          );
          return (
            <Form.Item key={question.id} label={question.text}>
              {question?.type === "MULTIPLE_CHOICE" ? (
                <Radio.Group
                  onChange={(e) =>
                    handleAnswerChange(question.id, e.target.value)
                  }
                  value={existingAnswer ? existingAnswer.answer : undefined}
                >
                  {question.options.map((option) => (
                    <Radio key={option.id} value={option.id}>
                      {option.text}
                    </Radio>
                  ))}
                </Radio.Group>
              ) : (
                <Input
                  onChange={(e) =>
                    handleAnswerChange(question.id, e.target.value)
                  }
                  value={existingAnswer ? existingAnswer.answer : ""}
                />
              )}
            </Form.Item>
          );
        })}
        <Form.Item label="Comment">
          <TextArea
            rows={4}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Verdict">
          <Select value={verdict} onChange={(value) => setVerdict(value)}>
            {reviewerVerdict.map((verdict) => (
              <Select.Option key={verdict.value} value={verdict.value}>
                {verdict.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Send Edit Notification to Reviewer">
          <Switch
            checked={sendNotification}
            onChange={setSendNotification}
            checkedChildren="Yes"
            unCheckedChildren="No"
          />
        </Form.Item>
        <Form.Item>
          {editReviewByChairSlice.loading ? (
            <Button type="primary" disabled>
              <Spin />
            </Button>
          ) : (
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditReviewByChair;
