import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

export const fetchActiveUsers = createAsyncThunk(
  "users/fetchActiveUsers",
  async () => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/user/status?blacklisted=false`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };

    const response = await axios.request(config);
    return response.data;
  }
);

// Fetch Blacklisted Users
export const fetchBlackListedUsers = createAsyncThunk(
  "users/fetchBlackListedUsers",
  async () => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/user/status?blacklisted=true`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };

    const response = await axios.request(config);
    return response.data;
  }
);

const userListSlice = createSlice({
  name: "userList",
  initialState: {
    active: { data: [], status: "idle", error: null },
    blackListed: { data: [], status: "idle", error: null },
  },
  reducers: {},
  extraReducers: (builder) => {
    // Active Users
    builder
      .addCase(fetchActiveUsers.pending, (state) => {
        state.active.status = "loading";
      })
      .addCase(fetchActiveUsers.fulfilled, (state, action) => {
        state.active.status = "succeeded";
        state.active.data = action.payload;
      })
      .addCase(fetchActiveUsers.rejected, (state, action) => {
        state.active.status = "failed";
        state.active.error = action.error.message;
        notification.error({
          message: "Error",
          description: action.payload.error,
        });
      });

    // Blacklisted Users
    builder
      .addCase(fetchBlackListedUsers.pending, (state) => {
        state.blackListed.status = "loading";
      })
      .addCase(fetchBlackListedUsers.fulfilled, (state, action) => {
        state.blackListed.status = "succeeded";
        state.blackListed.data = action.payload;
      })
      .addCase(fetchBlackListedUsers.rejected, (state, action) => {
        state.blackListed.status = "failed";
        state.blackListed.error = action.error.message;
        notification.error({
          message: "Error",
          description: action.payload.error,
        });
      });
  },
});

export default userListSlice.reducer;
