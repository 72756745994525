import React, { useState, useEffect } from "react";
import { Modal, Select, Button, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updatePaperStatus } from "../../store/slices/updatePaperSlice";
import { paperStatus } from "../../assets/enumConstants";

const ChangeStatusModal = ({ visible, onClose, record, setReload }) => {
  const [newStatus, setNewStatus] = useState("");
  const dispatch = useDispatch();

  const updatePaper = useSelector((state) => state.updatePaperStatus);

  useEffect(() => {
    if (record?.status) {
      setNewStatus(record.status);
    }
  }, [record]);

  const handleSave = () => {
    dispatch(
      updatePaperStatus({
        conferenceId: record.conferenceId,
        paperId: record.id,
        status: newStatus,
      })
    ).then(() => {
      setReload(Math.random());
      onClose();
    });
  };

  return (
    <Modal
      title="Change Status"
      visible={visible}
      // onOk={handleSave}
      onCancel={onClose}
      footer={
        updatePaper?.loading
          ? null
          : [
              <Button key="back" onClick={onClose}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={updatePaper?.loading}
                onClick={handleSave}
              >
                Save
              </Button>,
            ]
      }
    >
      {updatePaper?.loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin tip="Loading..." />
        </div>
      ) : (
        <Select
          value={newStatus}
          onChange={setNewStatus}
          style={{ width: "100%" }}
        >
          {paperStatus.map((status) => (
            <Select.Option key={status.value} value={status.value}>
              {status.label}
            </Select.Option>
          ))}
        </Select>
      )}
    </Modal>
  );
};

export default ChangeStatusModal;
