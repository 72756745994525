import logo1 from "./images/11.png";

export const logo = logo1;

export const paperStatus = [
  { value: "NEW", label: "New" },
  { value: "PENDING_REVIEW", label: "Pending Review" },
  { value: "REVIEWED", label: "Reviewed" },
  { value: "ACCEPTED", label: "Accepted" },
  { value: "REJECTED", label: "Rejected" },
  { value: "WITHDRAWN", label: "Withdrawn" },
  { value: "DESK_REJECT", label: "Desk Reject" },
  { value: "REGISTERED", label: "Registered" },
];

export const eventTypeList = [
  { value: "VIRTUAL", label: "Virtual" },
  { value: "OFFLINE", label: "Offline" },
  { value: "HYBRID", label: "Hybrid" },
];

export const reviewerVerdict = [
  { value: "APPROVED", label: "Approved" },
  { value: "REJECTED", label: "Rejected" },
];

export const fileAccepted = [
  { value: "PDF", label: "pdf" },
  { value: "DOC", label: "doc" },
  { value: "DOCX", label: "docx" },
  { value: "JPEG", label: "jpeg" },
  { value: "JPG", label: "jpg" },
  { value: "PNG", label: "png" },
  { value: "ZIP", label: "zip" },
];

export const fileAcceptedModal = [
  { value: "PDF", label: "application/pdf" },
  { value: "DOC", label: "application/msword" },
  {
    value: "DOCX",
    label:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  { value: "JPEG", label: "image/jpeg" },
  { value: "JPG", label: "image/jpeg" },
  { value: "PNG", label: "image/png" },
  { value: "ZIP", label: "application/zip" },
];
