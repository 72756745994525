import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

export const addSubmitReview = createAsyncThunk(
  "reviewForm/addSubmitReview",
  async ({ reviewId, conferenceId, verdict, comment, answers }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/conference/paper/review/submit`,
        {
          reviewId,
          conferenceId,
          verdict,
          comment,
          answers,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addReviewSlice = createSlice({
  name: "addSubmitReview",
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addSubmitReview.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addSubmitReview.fulfilled, (state) => {
        state.loading = false;
        notification.success({
          message: "Review submitted successfully!",
        });
      })
      .addCase(addSubmitReview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        notification.error({
          message: "Failed to submit review",
          description: action.payload.error,
        });
      });
  },
});

export default addReviewSlice.reducer;
