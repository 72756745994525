import { Button, Form, Input, Spin, Tabs, notification } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { checkJWTValidity } from "../../assets/constants";
import LoginTab from "./LoginTab";
import { logo } from "../../assets/enumConstants";

const { TabPane } = Tabs;

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Login");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signUpEmailDone, setSignUpEmailDone] = useState(false);
  const [signUpEmail, setSignUpEmail] = useState("");

  useEffect(() => {
    form.resetFields();
  }, [activeTab]);

  const handlePasswordSubmit = (values) => {
    const { otp, signUpPassword, signUpRePassword } = values;

    if (signUpPassword !== signUpRePassword) {
      notification.error({
        message: "Password Mismatch",
        description: "Passwords do not match",
      });
      return;
    }

    setLoading(true);
    const data = JSON.stringify({
      email: signUpEmail?.toLowerCase(),
      otp,
      password: signUpPassword,
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/auth/verify-and-set-password`,
        data,
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          localStorage.setItem("email", signUpEmail);
          setSignUpEmailDone(false);
          setActiveTab("Login");
          notification.success({
            message: "User registered successfully, Please Login again.",
          });
        } else {
          throw new Error("Invalid OTP");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        notification.error({
          message: "Password Set Failed",
          description: "Invalid OTP",
        });
      });
  };

  const handleSignUpSubmit = (values) => {
    const { signUpEmail } = values;

    setLoading(true);
    const data = JSON.stringify({ email: signUpEmail?.toLowerCase() });

    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/signup`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setSignUpEmail(signUpEmail); // Store the email
          setSignUpEmailDone(true);
          notification.success({
            message: "Registration Successful",
            description: "Check your email for the OTP",
          });
        } else {
          throw new Error("User already exists");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        notification.error({
          message: "Registration Failed",
          description: "User already exists",
        });
      });
  };

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const role = userData ? JSON.parse(userData).userType : "";
    if (checkJWTValidity()) {
      navigate(
        role === "SUPER_ADMIN" || role === "ADMIN"
          ? "/Adashboard"
          : "/dashboard"
      );
    }
  }, [navigate]);

  return (
    <div className="loginFormOuterContainer">
      <div className="loginFormInnerContainer neomorphism">
        <div className="loginFormInnerContainer_heading">
          <img className="logo" src={logo} alt="logo" />
        </div>

        <Tabs
          activeKey={activeTab}
          // className="neomorphism"
          onChange={setActiveTab}
        >
          <TabPane tab="Login" key="Login" style={{ width: "100%" }}>
            <LoginTab />
          </TabPane>

          <TabPane tab="SignUp" key="Signup">
            {!signUpEmailDone ? (
              <Form
                form={form}
                onFinish={handleSignUpSubmit}
                className="loginFormContainer"
              >
                <Form.Item
                  name="signUpEmail"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input
                    placeholder="Email"
                    onChange={(e) => setSignUpEmail(e.target.value)}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    disabled={loading}
                    type="primary"
                    htmlType="submit"
                    block
                  >
                    {loading ? <Spin tip="Loading..." /> : "Sign Up"}
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <Form
                form={form}
                onFinish={handlePasswordSubmit}
                className="loginFormContainer"
              >
                <Form.Item>
                  <Input value={signUpEmail} disabled />
                </Form.Item>
                <Form.Item
                  name="otp"
                  rules={[{ required: true, message: "Please input the OTP!" }]}
                >
                  <Input placeholder="OTP" maxLength={6} type="number" />
                </Form.Item>

                <Form.Item
                  name="signUpPassword"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password
                    placeholder="Password"
                    visibilityToggle={{
                      visible: showPassword,
                      onVisibleChange: setShowPassword,
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="signUpRePassword"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Re-enter Password"
                    visibilityToggle={{
                      visible: showPassword,
                      onVisibleChange: setShowPassword,
                    }}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    disabled={loading}
                    type="primary"
                    htmlType="submit"
                    block
                  >
                    {loading ? <Spin tip="Loading..." /> : "Set Password"}
                  </Button>
                </Form.Item>
              </Form>
            )}
          </TabPane>
        </Tabs>

        <div style={{ width: "100%" }}>
          <hr />
          {activeTab === "Login" ? (
            <Link className="spanClass" style={{ cursor: "pointer" }} to="/fp">
              Forgot Password?
            </Link>
          ) : (
            <Link className="spanClass" style={{ cursor: "pointer" }}>
              © 2024 ConferPro
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
