import React from "react";
import CreateEmailTemplate from "./CreateEmailTemplate";
import { useParams } from "react-router-dom";
import { Tabs } from "antd";
import ShowEmailTemplate from "./ShowEmailTemplate";

const EmailTemplateSetting = () => {
  const { confId } = useParams();

  const [reload, setReload] = React.useState(0);
  return (
    <div className="pageContainer">
      <h3>Email Templates Settings</h3>
      <Tabs defaultActiveKey="1" className="my-5">
        <Tabs.TabPane tab="Email Templates" key="1">
          <ShowEmailTemplate confId={confId} reload={reload} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Create Template" key="2">
          <CreateEmailTemplate confId={confId} setReload={setReload} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default EmailTemplateSetting;
