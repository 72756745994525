// src/slices/conferenceSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

// Initial state
const initialState = {
  data: null,
  status: "idle",
  loading: false,
  error: null,
};

export const fetchConferenceDetails = createAsyncThunk(
  "conference/fetchConferenceDetails",
  async (confid, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/conference/${confid}/conferencedetails`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      );

      const result = response.data;
      console.log(result);
      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateConferenceData = createAsyncThunk(
  "conference/updateConferenceData",
  async (conferenceDetails, { rejectWithValue }) => {
    try {
      const config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/conference/update`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        data: conferenceDetails,
      };

      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const conferenceSlice = createSlice({
  name: "conference",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetchConferenceDetails
    builder
      .addCase(fetchConferenceDetails.pending, (state) => {
        console.log("fetchConferenceDetails.pending");
        state.loading = true;
      })
      .addCase(fetchConferenceDetails.fulfilled, (state, action) => {
        console.log("fetchConferenceDetails.fulfilled");
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchConferenceDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // updateConferenceData
    builder
      .addCase(updateConferenceData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateConferenceData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        notification.success({
          message: "Conference updated successfully",
          description: "The conference has been updated successfully",
        });
      })
      .addCase(updateConferenceData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        notification.error({
          message: "Failed to update conference",
          description: action.payload.error,
        });
      });
  },
});

export default conferenceSlice.reducer;
