import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

export const addReviewerNotInDb = createAsyncThunk(
  "conference/addReviewerNotInDb",
  async (
    { conferenceId, email, name, affiliation, directly },
    { rejectWithValue }
  ) => {
    try {
      const data = JSON.stringify({
        conferenceId,
        email,
        name,
        affiliation,
        directly,
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/conference/reviewerDoesNotExists`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        data: data,
      };

      const response = await axios.request(config);
      return response.data; // Return the response data to the fulfilled case
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const addReviewerInDb = createAsyncThunk(
  "conference/addReviewerInDb",
  async ({ conferenceId, email, directly }, { rejectWithValue }) => {
    try {
      const data = JSON.stringify({
        conferenceId,
        email,
        directly,
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/conference/reviewerExists`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        data: data,
      };

      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

export const checkEmail = createAsyncThunk(
  "conference/checkEmail",
  async ({ email, conferenceId }, { rejectWithValue }) => {
    try {
      const data = JSON.stringify({
        email,
        conferenceId,
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/conference/checkEmail`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        data: data,
      };

      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const reviewAcceptance = createAsyncThunk(
  "conference/reviewAcceptance",
  async ({ conferenceId, email, id, accept }, { rejectWithValue }) => {
    try {
      const data = JSON.stringify({
        conferenceId,
        email,
        id,
        accept: accept?.toString(),
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/conference/reviewAcceptance`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        data: data,
      };

      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

const reviewAcceptanceSlice = createSlice({
  name: "reviewAcceptance",
  initialState: {
    loading: false,
    checkEmail: null,
    reviewAcceptance: null,
    error: null,
  },
  reducers: {
    resetState: (state) => {
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(reviewAcceptance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(reviewAcceptance.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        notification.success({
          message: "Success",
          description: "Review acceptance status updated successfully!",
        });
      })
      .addCase(reviewAcceptance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(checkEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.reviewAcceptance = action.payload;
      })
      .addCase(checkEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(addReviewerInDb.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addReviewerInDb.fulfilled, (state, action) => {
        state.loading = false;
        state.reviewAcceptance = action.payload;
        notification.success({
          message: "Success",
          description: "Reviewer added successfully!",
        });
      })
      .addCase(addReviewerInDb.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        notification.error({
          message: "Error",
          description: action.payload,
        });
      });
    builder
      .addCase(addReviewerNotInDb.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addReviewerNotInDb.fulfilled, (state, action) => {
        state.loading = false;
        state.reviewAcceptance = action.payload;
        notification.success({
          message: "Success",
          description: "Reviewer added successfully!",
        });
      })
      .addCase(addReviewerNotInDb.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        notification.error({
          message: "Error",
          description: action.payload,
        });
      });
  },
});

export const { resetState } = reviewAcceptanceSlice.actions;

export default reviewAcceptanceSlice.reducer;
