import React, { useState } from "react";
import { Form, Input, Button, notification, Spin } from "antd";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { logo } from "../../assets/enumConstants";

const ForgotPassword = () => {
  const [signUpEmail, setSignUpEmail] = useState("");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [setShowOtpScreen, setSetShowOtpScreen] = useState(false);

  const [loading, setLoading] = useState(false);

  const handlePasswordSubmit = (values) => {
    const { otp, signUpPassword, signUpRePassword } = values;

    if (signUpPassword !== signUpRePassword) {
      notification.error({
        message: "Password Mismatch",
        description: "Passwords do not match",
      });
      return;
    }

    setLoading(true);
    const data = JSON.stringify({
      email: signUpEmail,
      otp,
      password: signUpPassword,
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/auth/verify-and-set-password`,
        data,
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          localStorage.setItem("email", signUpEmail);
          navigate("/login");
          notification.success({
            message: "Password Changed Successfully, Please Login again.",
          });
        } else {
          throw new Error("Invalid OTP");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        notification.error({
          message: "Password Set Failed",
          description: "Invalid OTP",
        });
      });
  };

  const handleSignUpSubmit = () => {
    setLoading(true);
    const data = JSON.stringify({
      email: signUpEmail,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/auth/forgot/password/email`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.success === true) {
          notification.success({
            message: "OTP Sent Successfully",
          });
        }
        setLoading(false);
        setSetShowOtpScreen(true);
      })
      .catch((error) => {
        notification.error({
          message: "OTP Sending Failed",
          description: "Invalid Email",
        });
        setLoading(false);
      });
  };

  return (
    <div className="loginFormOuterContainer">
      <div className="loginFormInnerContainer neomorphism">
        <img className="logo" src={logo} alt="logo" />

        <div className="loginForm">
          <h1>Forgot Password</h1>
          {!setShowOtpScreen ? (
            <Form
              form={form}
              onFinish={handleSignUpSubmit}
              className="loginFormContainer"
            >
              <Form.Item
                name="signUpEmail"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input
                  placeholder="Email"
                  onChange={(e) => setSignUpEmail(e.target.value)}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  disabled={loading}
                  type="primary"
                  htmlType="submit"
                  block
                >
                  {loading ? <Spin tip="Loading..." /> : "Sign Up"}
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <Form
              form={form}
              onFinish={handlePasswordSubmit}
              className="loginFormContainer"
            >
              <Form.Item>
                <Input value={signUpEmail} disabled />
              </Form.Item>
              <Form.Item
                name="otp"
                rules={[{ required: true, message: "Please input the OTP!" }]}
              >
                <Input placeholder="OTP" maxLength={6} type="number" />
              </Form.Item>

              <Form.Item
                name="signUpPassword"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  visibilityToggle={{
                    visible: showPassword,
                    onVisibleChange: setShowPassword,
                  }}
                />
              </Form.Item>

              <Form.Item
                name="signUpRePassword"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Re-enter Password"
                  visibilityToggle={{
                    visible: showPassword,
                    onVisibleChange: setShowPassword,
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  disabled={loading}
                  type="primary"
                  htmlType="submit"
                  block
                >
                  {loading ? <Spin tip="Loading..." /> : "Set Password"}
                </Button>
              </Form.Item>
            </Form>
          )}
        </div>

        <div>
          <hr style={{ width: "100%" }} />

          <span className="spanClass">
            Already Have Accout?{" "}
            <Link style={{ cursor: "pointer" }} to="/">
              Back to Login
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
