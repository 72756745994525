import { Button, Popconfirm, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteConferenceEmailTemplate,
  fetchConferenceTemplates,
} from "../../store/slices/emailTemplateFetchSlice";

const ShowEmailTemplate = ({ confId, reload }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchConferenceTemplates(confId));
  }, [confId, dispatch, reload]);

  const conferenceEmailTemplates = useSelector(
    (state) => state.conferenceEmailTemplates
  );

  const handleDelete = (key) => {
    console.log("Delete:", key);
    dispatch(deleteConferenceEmailTemplate(key)).then(() => {
      dispatch(fetchConferenceTemplates(confId));
    });
  };

  const columns = [
    {
      title: "Template Name",
      dataIndex: "templateName",
      key: "templateName",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Created on",
      dataIndex: "updatedAt",
      key: "createdOn",
      render: (text) => <span>{new Date(text).toDateString()}</span>,
    },
    {
      title: "Options",
      key: "options",
      render: (_, record) => (
        <div>
          <Popconfirm
            title="Are you sure to delete this template?"
            onConfirm={() => handleDelete(record.id)}
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const expandedRowRender = (record) => (
    <div>
      <p>
        <strong>Body:</strong> {record?.templateBody || "N/A"}
      </p>
    </div>
  );

  if (conferenceEmailTemplates.loading) {
    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Spin tip="Loading..." />
      </div>
    );
  }

  return (
    <div>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender,
        }}
        dataSource={conferenceEmailTemplates?.templates ?? []}
        rowKey="id"
      />
    </div>
  );
};

export default ShowEmailTemplate;
