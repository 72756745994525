import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

export const deleteSubmission = createAsyncThunk(
  "submissions/deleteSubmission",
  async (submissionId, { rejectWithValue }) => {
    try {
      const config = {
        method: "delete",
        url: `${process.env.REACT_APP_API_URL}/conference/paper/${submissionId}/delete`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };

      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const submissionDeleteSlice = createSlice({
  name: "paperSubmissionsDelete",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteSubmission.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteSubmission.fulfilled, (state, action) => {
        state.status = "succeeded";
        notification.success({
          message: "Success",
          description: "Submission deleted successfully.",
        });
      })
      .addCase(deleteSubmission.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        notification.error({
          message: "Error",
          description: action.payload.error,
        });
      });
  },
});

export default submissionDeleteSlice.reducer;
