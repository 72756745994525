import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";
import { useDispatch } from "react-redux";

export const changeConferenceStatus = createAsyncThunk(
  "conference/changeConferenceStatus",
  async ({ conferenceId, approved }, { rejectWithValue }) => {
    try {
      const config = {
        method: "patch",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/conference/${conferenceId}/changestatus?approved=${approved}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };

      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to change conference status",
      });

      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const changeConferenceStatusSlice = createSlice({
  name: "changeConferenceStatus",
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changeConferenceStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changeConferenceStatus.fulfilled, (state, action) => {
        state.loading = false;
        notification.success({
          message: "Success",
          description: "Conference status changed successfully",
        });
      })
      .addCase(changeConferenceStatus.rejected, (state, action) => {
        state.loading = false;
        notification.error({
          message: "Error",
          description: action.payload.error,
        });
      });
  },
});

export default changeConferenceStatusSlice.reducer;
