import { Modal, Spin } from "antd";
import React from "react";
import { Form, Input, Button, Select } from "antd";
import { fileAccepted } from "../../assets/enumConstants";
import { useDispatch, useSelector } from "react-redux";
import { createSubmissionSetting } from "../../store/slices/addDocumentSubSlice";
import { fetchConferenceDetails } from "../../store/slices/conferenceSlice";

const { Option } = Select;

const AddDocSubmissionModal = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const submissionSettingsSlice = useSelector(
    (state) => state.submissionSettingsSlice
  );

  const onFinish = (values) => {
    const data = [
      {
        name: values.name,
        docType: values.docType,
      },
    ];

    let confId = props.confId;
    console.log("Data to be submitted:", data);

    dispatch(createSubmissionSetting({ data, confId })).then((res) => {
      dispatch(fetchConferenceDetails(confId));
      form.resetFields();
      props.setIsAddDocModalVisible(false);
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      title="Add Document Submission"
      open={props.isAddDocModalVisible}
      footer={null}
      onCancel={() => props.setIsAddDocModalVisible(false)}
    >
      {submissionSettingsSlice.loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin />
        </div>
      ) : (
        <Form
          form={form}
          layout="vertical"
          name="documentForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Document Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter the document name!",
              },
            ]}
          >
            <Input placeholder="Enter document name" />
          </Form.Item>

          <Form.Item
            label="Document Type"
            name="docType"
            rules={[
              {
                required: true,
                message: "Please select at least one document type!",
              },
            ]}
          >
            <Select mode="multiple" placeholder="Select document type">
              {fileAccepted.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default AddDocSubmissionModal;
