import React, { useState } from "react";
import { Modal, Form, Input, Button, Select, Space, Spin } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { addQuestion } from "../../store/slices/addConfferenceQuestionSlice";
import { fetchQuestions } from "../../store/slices/fetchQuestionsSlice";

const { Option } = Select;

const ConferenceAddQuestionModal = ({ visible, onClose, confId }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [questionType, setQuestionType] = useState("TEXT");

  const addquestionsstate = useSelector((state) => state.addquestionsSlice);

  const handleQuestionTypeChange = (value) => {
    setQuestionType(value);
  };

  const handleFinish = async (values) => {
    const formattedData = {
      questions: [
        {
          text: values.text,
          type: values.type,
          ...(values.type === "MULTIPLE_CHOICE" && {
            options: values.options.map((option) => ({
              text: option.text, // Only keeping the text for options
            })),
          }),
        },
      ],
    };

    dispatch(addQuestion({ confId, questionData: formattedData }))
      .unwrap()
      .then(() => {
        onClose();
        dispatch(fetchQuestions(confId));
      })
      .catch((error) => {
        console.error("Error adding question:", error);
      });
  };

  return (
    <Modal
      title="Add New Question"
      open={visible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Form
        form={form}
        onFinish={handleFinish}
        layout="vertical"
        initialValues={{ type: "TEXT" }}
      >
        <Form.Item
          name="text"
          label="Question Text"
          rules={[
            { required: true, message: "Please enter the question text" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="type"
          label="Question Type"
          rules={[
            { required: true, message: "Please select the question type" },
          ]}
        >
          <Select onChange={handleQuestionTypeChange}>
            <Option value="TEXT">Text</Option>
            <Option value="MULTIPLE_CHOICE">Multiple Choice</Option>
          </Select>
        </Form.Item>

        {questionType === "MULTIPLE_CHOICE" && (
          <Form.List name="options">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "text"]}
                      fieldKey={[fieldKey, "text"]}
                      rules={[
                        {
                          required: true,
                          message: "Option text is required",
                        },
                      ]}
                    >
                      <Input placeholder="Option text" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Option
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        )}

        <Form.Item>
          {addquestionsstate?.loading ? (
            <Button type="link" disabled>
              <Spin />
            </Button>
          ) : (
            <Button type="primary" htmlType="submit">
              Add Question
            </Button>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ConferenceAddQuestionModal;
