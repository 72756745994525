import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

export const downloadReviewerCSV = createAsyncThunk(
  "conference/downloadReviewerCSV",
  async (conferenceId, { rejectWithValue }) => {
    try {
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/conference/${conferenceId}/reviewer/csv/download`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        responseType: "blob",
      };

      const response = await axios.request(config);
      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "reviewers.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const reviewerCSVSlice = createSlice({
  name: "reviewerCSV",
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadReviewerCSV.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadReviewerCSV.fulfilled, (state) => {
        state.loading = false;
        notification.success({
          message: "Success",
          description: "CSV downloaded successfully",
        });
      })
      .addCase(downloadReviewerCSV.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to download CSV";
        notification.error({
          message: "Failed",
          description: action.payload || "Failed to download CSV",
        });
      });
  },
});

export default reviewerCSVSlice.reducer;
