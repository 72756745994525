import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

export const fetchReviewers = createAsyncThunk(
  "reviewers/fetchReviewers",
  async (conferenceId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/conference/${conferenceId}/reviewers`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      }
    );
    console.log(response.data.data);
    return response.data;
  }
);

export const fetchReviewerInviteeInfo = createAsyncThunk(
  "conference/fetchReviewerInviteeInfo",
  async (conferenceId, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/conference/getReviewerInviteeInfo`,
        {
          conferenceId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch data");
    }
  }
);

export const addReviewer = createAsyncThunk(
  "reviewers/addReviewer",
  async ({ conferenceId, email }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/conference/reviewer/add`,
      {
        conferenceId,
        reviewerEmail: email,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      }
    );

    return response.data;
  }
);

export const processReviewers = createAsyncThunk(
  "conference/processReviewers",
  async ({ filePath, conferenceId }, { rejectWithValue }) => {
    try {
      const data = new FormData();
      data.append("file", filePath);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/conference/processReviewers/${conferenceId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          maxBodyLength: Infinity,
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const reviewersSlice = createSlice({
  name: "reviewerSlice",
  initialState: {
    reviewers: [],
    pendingReviewers: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviewers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchReviewers.fulfilled, (state, action) => {
        state.loading = false;
        state.reviewers = action.payload.data;
      })
      .addCase(fetchReviewers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        notification.error({ message: "Failed to fetch reviewers." });
      })
      .addCase(addReviewer.pending, (state) => {
        state.loading = true;
      })
      .addCase(addReviewer.fulfilled, (state, action) => {
        state.loading = false;
        notification.success({ message: "Reviewer added successfully." });
      })
      .addCase(addReviewer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        notification.error({
          message: "Failed to add reviewer.",
          description: action.payload.error,
        });
      })
      .addCase(fetchReviewerInviteeInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReviewerInviteeInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.pendingReviewers = action.payload.data;
      })
      .addCase(fetchReviewerInviteeInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        notification.error({ message: "Failed to fetch reviewers." });
      })

      .addCase(processReviewers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(processReviewers.fulfilled, (state, action) => {
        state.loading = false;
        notification.warning({
          message:
            "Reviewer processing is currently underway. You will receive an email with the status update shortly.",
        });
      })
      .addCase(processReviewers.rejected, (state, action) => {
        state.loading = false;
        notification.error({ message: "Failed to process reviewers." });
      });
  },
});

export default reviewersSlice.reducer;
