import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";
import FormData from "form-data";

export const uploadPaperSubmission = createAsyncThunk(
  "paper/addSubmissionDocSlice",
  async ({ paperId, submissionSettings, file }, thunkAPI) => {
    console.log("file", file);
    console.log("paperId", paperId);
    console.log("submissionSettings", submissionSettings);
    try {
      const data = new FormData();

      data.append("paperId", paperId);
      submissionSettings.forEach((setting) =>
        data.append("submissionSettings[]", setting)
      );
      data.append("files", file);

      console.log("data", data);

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/conference/paper/submission/submit`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        data: data,
      };

      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addSubmissionDocSlice = createSlice({
  name: "addSubmissionDocSlice",
  initialState: {
    loading: false,
    success: false,
    error: null,
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadPaperSubmission.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(uploadPaperSubmission.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.data = action.payload;
        notification.success({
          message: "Success",
          description: "Submission uploaded successfully!",
        });
      })
      .addCase(uploadPaperSubmission.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
        notification.error({
          message: "Error",
          //   description: action.payload.error,
        });
      });
  },
});

export default addSubmissionDocSlice.reducer;
