// src/store/slices/adminAddreviewerSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

export const assignReviewer = createAsyncThunk(
  "admin/assignReviewer",
  async ({ paperId, reviewerId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/conference/paper/reviewer/assign`,
        { paperId, reviewerId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const assignReviewerSlice = createSlice({
  name: "assignReviewerTopaper",
  initialState: {
    reviewers: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(assignReviewer.pending, (state) => {
        state.loading = true;
      })
      .addCase(assignReviewer.fulfilled, (state, action) => {
        state.loading = false;
        console.log("adsasdasd");
        notification.success({
          message: "Success",
          description: "Reviewer assigned successfully.",
        });
      })
      .addCase(assignReviewer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        notification.error({
          message: "Error",
          description: action.payload.error,
        });
      });
  },
});

export default assignReviewerSlice.reducer;
